import { clearEmptyFields } from 'common/utils';
import { toPaginationParams } from '../../helpers';
import { httpClient } from '../../httpClient';
import { DataQueryFunction } from '../../types';

export const querySntToMptSearch: DataQueryFunction = (variables: any) => {
  const { filter, pagination } = variables || {};

  const criteria = JSON.stringify(clearEmptyFields(filter));

  return httpClient.get(`/sntToMpt/search`, {
    params: {
      criteria,
      ...toPaginationParams(pagination),
      order: 'desc',
    },
  });
};
