import React, { useCallback } from 'react';
import { queryCheckpoints } from 'rest';
import { LocaleStore, useLocale } from 'services/l10n';
import { FormSelect, FormSelectProps } from 'ui/common';

export async function getCheckpointOptions({
  locale,
  preProcessOptions,
  withEmpty = true,
}: {
  locale: LocaleStore;
  preProcessOptions?: (options: Array<any>) => Array<any>;
  withEmpty?: boolean;
}) {
  const { loc } = locale;
  const emptyOptions = withEmpty
    ? [
        {
          value: '',
          label: loc('common.option.notSelected'),
        },
      ]
    : [];
  const res = await queryCheckpoints();
  if (Array.isArray(res.data?.rows)) {
    if (typeof preProcessOptions === 'function') {
      return preProcessOptions(res.data?.rows);
    } else {
      return [...emptyOptions, ...res.data.rows];
    }
  }
  return emptyOptions;
}

export const getCheckpointsOptionLabel = (data: any, locale: LocaleStore) => {
  const { loc, choose } = locale;
  const type = data.type ? loc(`checkpoint.${data.type}`) : undefined;
  return data.label ?? `(${data.code}) ${choose([data.checkpointRu, data.checkpointKz])} ${type ? ' - ' + type : ''}`;
};

type Props = FormSelectProps & {
  preProcessOptions?: (options: Array<any>) => Array<any>;
  value?: any;
};

export const FormCheckpointSelect: React.FC<Props> = ({ preProcessOptions, value, field, ...rest }) => {
  const locale = useLocale();
  const { loc } = locale;

  const getOptionValue = useCallback((data) => {
    return data.value ?? data.code;
  }, []);

  const getOptionLabel = useCallback(
    (data) => {
      return getCheckpointsOptionLabel(data, locale);
    },
    [locale]
  );

  const noOptionsMessage = useCallback(() => loc('common.option.noOptions'), [loc]);

  return (
    <FormSelect
      {...rest}
      field={field && value ? { ...field, value } : field}
      fetchOptions={() => getCheckpointOptions({ locale, preProcessOptions })}
      isSearchable={true}
      noOptionsMessage={noOptionsMessage}
      getOptionValue={getOptionValue}
      getOptionLabel={getOptionLabel}
    />
  );
};
