import { httpClient } from '../../httpClient';
import { DataQueryFunction } from '../../types';

export const querySntHistory: DataQueryFunction = async (variables: any) => {
  const { sntId } = variables || {};

  const res = await httpClient.get(`snt/history`, { params: { sntId } });
  if (res.data?.success === false || !res.data) {
    throw res;
  } else {
    return res;
  }
};
