import React, { useCallback } from 'react';
import { queryCountries } from 'rest';
import { LocaleStore, useLocale } from 'services/l10n';
import { FormSelect, FormSelectProps, UITheme } from 'ui/common';
import { getCountryCode } from '../code-values/CodeValueCountry';
import { createFilter } from 'react-select';

const EAEC_COUNTRY = ['RU', 'KZ', 'AM', 'BY', 'KG'];

const EAEC_COUNTRY_MAP = Object.fromEntries(EAEC_COUNTRY.map((key) => [key, true]));

async function getCountries(): Promise<any[]> {
  const res = await queryCountries();
  if (Array.isArray(res.data)) {
    return res.data;
  }
  return [];
}

export const getCountryOptionValue = (data: any) => {
  return data?.value ?? getCountryCode(data);
};

export const getCountryOptionLabel = (data: any, locale: LocaleStore) => {
  const { choose } = locale;
  return data?.label ?? `${getCountryCode(data)} - ${choose([data?.nameRu, data?.nameKz])}`;
};

const defaultFilterOption = createFilter<any>();

type Props = FormSelectProps & {
  onlyEaec?: boolean;
  excludeEaec?: boolean;
  excludeKZ?: boolean;
  theme?: UITheme;
};

export const FormCountrySelect: React.FC<Props> = ({
  onlyEaec,
  excludeEaec,
  excludeKZ,
  theme,
  isDisabled,
  readOnly,
  ...rest
}) => {
  const locale = useLocale();
  const { loc } = locale;

  const noOptionsMessage = useCallback(() => loc('common.option.noOptions'), [loc]);

  const fetchOptions = useCallback(async () => {
    const countries = await getCountries();
    const options = countries.filter((opt) => !!getCountryOptionValue(opt));

    return [
      {
        value: '',
        label: loc('common.option.notSelected'),
      },
      ...options,
    ];
  }, [loc]);

  const filterOptions = (opt: any, term: string) => {
    if (isDisabled || readOnly) {
      return true;
    } else {
      let exclude = false;
      if (excludeKZ && getCountryOptionValue(opt) === 'KZ') {
        exclude = true;
      }
      if (onlyEaec && !EAEC_COUNTRY_MAP[getCountryOptionValue(opt)]) {
        exclude = true;
      }
      if (excludeEaec && EAEC_COUNTRY_MAP[getCountryOptionValue(opt)]) {
        exclude = true;
      }
      return !exclude && defaultFilterOption(opt, term);
    }
  };

  return (
    <FormSelect
      {...rest}
      fetchOptions={fetchOptions}
      filterOption={filterOptions}
      isSearchable={true}
      getOptionLabel={(opt) => getCountryOptionLabel(opt, locale)}
      getOptionValue={getCountryOptionValue}
      noOptionsMessage={noOptionsMessage}
      theme={theme}
      isDisabled={isDisabled}
      readOnly={readOnly}
    />
  );
};
