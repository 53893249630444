import { clearEmptyFields } from 'common/utils';
import { httpClient } from 'rest/httpClient';
import { DataQueryFunction } from 'rest/types';

export const queryVstoreFindGsvsMetaByFixedIds: DataQueryFunction = async ({ date, id }) => {
  const res = await httpClient.get(`/vstore/rest/gsvs/findGsvsMetaByFixedIds`, {
    params: { ...clearEmptyFields({ fixedIds: id, date }) },
  });

  if (res.data?.success === false || !res.data) {
    throw res;
  } else {
    return res;
  }
};
