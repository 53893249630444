import React, { useCallback } from 'react';
import { queryMeasureUnit } from 'rest';
import { LocaleStore, useLocale } from 'services/l10n';
import { FormSelect, FormSelectProps } from 'ui/common';

async function getMeasureUnitOptions({ loc }: LocaleStore, unitCodeSet?: 'beer' | 'tobacco') {
  const emptyOptions = [
    {
      value: '',
      label: loc('common.option.notSelected'),
    },
  ];
  const res = await queryMeasureUnit();
  if (Array.isArray(res.data)) {
    return [
      ...emptyOptions,
      ...res.data.filter((unit) => {
        if (unitCodeSet) {
          if (unitCodeSet === 'beer') {
            return BEER_SET.has(unit.code);
          }
          if (unitCodeSet === 'tobacco') {
            return TOBACCO_SET.has(unit.code);
          }
        }
        return unit;
      }),
    ];
  }
  return emptyOptions;
}

type Props = FormSelectProps & {
  /**
   * Набор единиц измерения (пиво, табак)
   */
  unitCodeSet?: 'beer' | 'tobacco';
};

export const FormMeasureUnitSelect: React.FC<Props> = ({ unitCodeSet, ...rest }) => {
  const locale = useLocale();
  const { loc, choose } = locale;

  const getOptionLabel = useCallback(
    (data) => {
      return data.label ?? choose([data.nameRu, data.nameKz]);
    },
    [choose]
  );

  const getOptionValue = useCallback((data) => {
    return data.value ?? data.code;
  }, []);

  const noOptionsMessage = useCallback(() => loc('common.option.noOptions'), [loc]);

  return (
    <FormSelect
      {...rest}
      fetchOptions={() => getMeasureUnitOptions(locale, unitCodeSet)}
      isSearchable={true}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      noOptionsMessage={noOptionsMessage}
    />
  );
};

const BEER_SET = new Set([
  '778', // Упаковка
  '868', // Бутылка
  '881', // Банка условная
  '991', // Кег
  '112', // Литр
]);

const TOBACCO_SET = new Set([
  '8751', // Коробка
  '992', // Блок
  '5111', // Одна пачка
  '796', // Штука
  '166', // Килограмм
  '111', // Миллилитр
  '944', // Капсула
  '945', // Картридж
]);
