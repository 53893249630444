import { clearEmptyFields } from 'common/utils';
import { createCachedQuery, toPaginationParams } from 'rest/helpers';
import { httpClient } from 'rest/httpClient';
import { DataQueryFunction } from 'rest/types';

export const queryDictPinSearch: DataQueryFunction = async ({ pinType, filter, pagination }) => {
  let criteria: any = JSON.stringify(clearEmptyFields({ ...filter }));

  return httpClient.get(`/dict/pin/${pinType}/search`, { params: { criteria, ...toPaginationParams(pagination) } });
};

export const queryDictPinSearchCached: DataQueryFunction = createCachedQuery(queryDictPinSearch);
