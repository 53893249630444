export const PATH = {
  MAIN: '/',
  LOGIN: '/login',
  REGISTRATION: '/registration',
  RESET_PASSWORD: '/reset-password',
  CHANGE_EMAIL: '/change-email',

  PAGES: '/pages',
  TEST_STAND: '/test-stand',
  API: '/api',
  VIDEO_TUTOR: '/video-tutor',
  GUIDES: '/guides',
  INSIDE_NEWS: '/news',
  INSIDE_NEWS_ITEM: '/news/:id',

  MPT_JOURNAL: '/mpt',
  FNO_MATCH_SNT_JOURNAL: '/fno-matching/snt',
  FNO_MATCH_SNT: '/fno-matching/snt/:id',
  KKM_JOURNAL: '/kkm',
  KKM_WRITEOFF_JOURNAL: '/kkm-writeoff',
  KKM: '/kkm/:id',

  ESF_BLANK: '/esf/:mode/:id',
  ESF_BLANK_NEW: '/esf/:mode',
  ESF_JOURNAL: '/esf',
  SNT_JOURNAL: '/snt',
  SNT_FORM: '/snt/:mode/:id',
  SNT_FORM_NEW: '/snt/:mode',
  SALDO_REPORT: '/saldo-report',
  PRINT_JOURNAL: '/print-journal/:id',
  ZVT_JOURNAL: '/zvt',
  SETTINGS_VS: '/settings-vs',
  PROFILE_SELECT: '/profile-select',
  REMAINDER: '/remainder-goods',
  FNO: '/fno',
  DT: '/dt',
  CERTIFICATES: '/certificates',
  MESSAGES: '/messages',
  DOC_TRANSFER: '/doc-transfer',

  ARM: '/arm',

  // ARM_USER_DEAILS: '/user-details',
  ARM_USERS: '/users',
  OGD: '/ogd',
  NEWS: '/news',
  INTERNAL_USERS: '/internal-users',
  EXTERNAL_USERS: '/external-users',
  SNT_BORDER_CROSSING_REPORT: '/snt-border-crossing-report',
  VIRTUAL_LABEL_CHAIN: '/virtual-label-chain',
  STATISTIC_DOCFLOW_REPORT: '/statistic-docflow-report',
  ASSURANCE_JOURNAL: '/assurance',
  EAEC_CODE_GENERATION: '/eaec-code-generation',
  SMALL_RETAIL: '/small-retail',
  SEND_MESSAGE: '/send-message',
  MESSAGES_JOURNAL: '/messages',
  SNT_TERMS_REGISTRATION: '/snt-terms-registration',
  SNT_TERMS_CONFIRMATION: '/snt-terms-confirmation',
  EAEC_DOCUMENTS_INPUT: '/eaec-documents-input',
  EAEC_DOCUMENTS_OUTPUT: '/eaec-documents-output',
  EAEC_OPERATIONS_INPUT: '/eaec-operations-input',
  EAEC_OPERATIONS_OUTPUT: '/eaec-operations-output',
  EAEC_MONITORING: '/eaec-monitoring',
  EAEC_TRANSACTION: '/eaec-transaction',
  QOLDAY_REGISTRY: '/qolday-registry',
  NOT_BIO_TAXPAYERS: '/not-bio-taxpayers',
  BIO_RISKY: '/bio-risky',
  GOV_INST: '/gov-inst',
  BIG_COMPANIES: '/big-companies',

  DOCUMENT_LINK: '/document',
  DEV: '/dev',
  EAEC_PASSPORT_VERIFY: '/eaec/passport',
  EAEC_PASSPORT: '/eaec/passport/:id',
  EAEC_LOGIN: '/eaec/login',
};
