import { ProfileType } from 'common/constants';
import { resolveTaxpayerFio, resolveTaxpayerNameOrFio } from 'common/helpers/taxpayer';
import React, { useCallback } from 'react';
import { useLocale } from 'services/l10n';
import { FormInputAutocomplete, FormInputAutocompleteProps } from 'ui/common';
import { getLoadIINOptions } from './FormAutocompleteIIN';

type Props = Omit<FormInputAutocompleteProps, 'loadOptions' | 'conditionForLoad'> & {
  /**
   * Поиск с разделением выбора для Индивидуальный предприниматель (на ИП и ФЛ),
   * и добавлнием (Адвокат, Нотариус и тд.) для ФЛ.
   */
  extended?: boolean;
  /**
   * При использовании при регистрации, для неавторизованного пользователя
   */
  registration?: boolean;
  /**
   * Использовать InputAutocomplete или Autocomplete (на основе React Select)
   */
};

export const FormInputAutocompleteIIN: React.FC<Props> = ({
  field,
  extended = false,
  registration = false,
  ...rest
}) => {
  const locale = useLocale();
  const { loc } = locale;
  const getOptionLabel = useCallback(
    (data) => {
      if (extended) {
        if (data.type === ProfileType.INDIVIDUAL) {
          return `${data.tin} ${resolveTaxpayerFio(data, locale)} (${loc('common.labels.fl')})`;
        }
        if ([ProfileType.LAWYER, ProfileType.BAILIFF, ProfileType.MEDIATOR, ProfileType.NOTARY].includes(data.type)) {
          return `${data.tin} ${resolveTaxpayerFio(data, locale)} ${loc(`common.profileType.${data.type}`)}`;
        }
        return `${data.tin} ${resolveTaxpayerNameOrFio(data, locale)}`;
      }
      return `${data.tin} ${resolveTaxpayerFio(data, locale)}`;
    },
    [locale, loc, extended]
  );

  const getOptionValue = useCallback((data) => {
    return data?.tin || '';
  }, []);

  return (
    <FormInputAutocomplete
      loadOptions={getLoadIINOptions(extended, registration)}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      onlyDigits
      field={field}
      {...rest}
    />
  );
};
