import React, { useState } from 'react';
import clsx from 'clsx';
import s from './ImportModal.module.scss';
import { Button, Uploader } from 'ui/common';
import { ModalFooter, ModalHeader } from 'services/modal';
import { useLocale } from 'services/l10n';

type Props = {
  className?: string;
  title?: string;
  onClose?: () => void;
  onSuccess: (result: any) => void;
  sendImported: (files: File[]) => Promise<any>;
  /**
   * позволять выбрать несколько файлов, если false то только один
   */
  multiple?: boolean;
};

export const ImportModal: React.FC<Props> = ({
  className,
  title,
  onClose,
  onSuccess,
  sendImported,
  multiple = true,
}) => {
  const locale = useLocale();
  const { loc } = locale;

  const [files, setFiles] = useState<File[]>([]);
  const [loading, setLoading] = useState(false);

  const onSend = async () => {
    setLoading(true);
    try {
      const res = await sendImported(files);
      onSuccess(res);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const removeFile = (index: number) => {
    setFiles((state) => (multiple ? state.filter((fl, idx) => idx !== index) : []));
  };

  const addFile = (files: Array<File>) => {
    setFiles((state) => (multiple ? [...state, ...files] : [files[0]]));
  };

  return (
    <div className={clsx(className, s.wrapper)}>
      <ModalHeader>
        {title || (multiple ? loc('common.title.documentsImport') : loc('common.title.documentImport'))}
      </ModalHeader>
      <div className={s.container}>
        <Uploader
          label={multiple ? loc('common.button.chooseFiles') : loc('common.button.chooseFile')}
          onSelect={addFile}
          files={files}
          onRemove={removeFile}
          multiple={multiple}
        />
      </div>
      <ModalFooter>
        <Button className={s.button} variant="secondary" onClick={onClose} disabled={loading}>
          {loc('common.button.cancel')}
        </Button>
        <Button className={s.button} onClick={onSend} disabled={!files.length || loading}>
          {loc('common.button.upload')}
        </Button>
      </ModalFooter>
    </div>
  );
};
