import clsx from 'clsx';
import { nanoid } from 'nanoid';
import React, { forwardRef, Fragment, SyntheticEvent, useEffect, useMemo, useRef, useState } from 'react';
import { Rifm } from 'rifm';

const parseDigits = (string: string) => (string.match(/\d+/g) || []).join('');

const formatDate = (string: string) => {
  const digits = parseDigits(string);
  const chars = digits.split('');
  return chars.reduce((r, v, index) => (index === 2 || index === 4 ? `${r}.${v}` : `${r}${v}`), '').slice(0, 10);
};

const formatDateTime = (string: string) => {
  const separators: { [key: number]: string } = {
    2: '.',
    4: '.',
    6: ' ',
    8: ':',
  };
  const digits = parseDigits(string);
  const chars = digits.split('');
  return chars.reduce((r, v, index) => `${r}${separators[index] || ''}${v}`, '').slice(0, 16);
};

const addDateMask = (string: string) => {
  const digits = parseDigits(string);
  const days = digits.slice(0, 2).padEnd(2, '_');
  const months = digits.slice(2, 4).padEnd(2, '_');
  const years = digits.slice(4, 8).padEnd(4, '_');
  return `${days}.${months}.${years}`;
};

const addDateTimeMask = (string: string) => {
  const digits = parseDigits(string);
  const days = digits.slice(0, 2).padEnd(2, '_');
  const months = digits.slice(2, 4).padEnd(2, '_');
  const years = digits.slice(4, 8).padEnd(4, '_');
  const hours = digits.slice(8, 10).padEnd(2, '_');
  const mins = digits.slice(10, 12).padEnd(2, '_');
  return `${days}.${months}.${years} ${hours}:${mins}`;
};

const unMaskEmpty = (string: string) => {
  return parseDigits(string).length ? string : '';
};

type Props = {
  label?: string;
  id?: string;
  variant?: 'mui' | 'product-table';
  className?: string;
  value?: any;
  onChange?: (value: string, event: React.SyntheticEvent<any, Event> | undefined) => void;
  onFocus?: (e: SyntheticEvent) => void;
  onBlur?: (e: SyntheticEvent) => void;
  placeholder?: string;
  showTimeSelect?: boolean;
};

export const DatePickerInput = forwardRef<any, Props>((props, ref) => {
  const { className, value, onChange, showTimeSelect, placeholder, onFocus, onBlur, label, id, variant, ...rest } =
    props;
  const dummyInputRef = useRef<any>(null);

  const [focused, setFocused] = useState(false);

  const onChangeRef = useRef<any>(null);
  onChangeRef.current = onChange;

  const onRifmChange = (newValue: string) => {
    // console.log('rifm onChange', newValue, '>>', value);
    if (dummyInputRef.current) {
      const e = new Event('input', { bubbles: true });
      dummyInputRef.current.value = unMaskEmpty(newValue);
      dummyInputRef.current.dispatchEvent(e);
    }
  };

  useEffect(() => {
    const dummyInput = document.createElement('input');
    dummyInput.type = 'text';
    dummyInput.addEventListener('input', (ev: any) => {
      // console.log('dummyInput onChange', ev.target.value);
      onChangeRef.current(ev);
    });
    dummyInputRef.current = dummyInput;
    return () => {
      dummyInput.remove();
    };
  }, []);

  const inputId = useMemo(() => id || nanoid(5), []); // eslint-disable-line

  return (
    <Rifm
      accept={/[\d]/g}
      mask={true}
      format={showTimeSelect ? formatDateTime : formatDate}
      replace={showTimeSelect ? addDateTimeMask : addDateMask}
      value={value}
      onChange={(v) => {
        onRifmChange(v);
      }}
    >
      {({ value: inputValue, onChange: inputOnChange }) => (
        <Fragment>
          <input
            ref={ref}
            placeholder={variant === 'mui' ? undefined : placeholder}
            {...rest}
            value={(placeholder || variant === 'mui') && !value && !focused ? '' : inputValue}
            // value={inputValue}
            onChange={(e) => {
              // console.log('input onChange', e.target.value);
              inputOnChange(e);
            }}
            onFocus={(e) => {
              setFocused(true);
              onFocus!(e);
            }}
            onBlur={(e) => {
              setFocused(false);
              onBlur!(e);
            }}
            className={clsx(className, 'date-picker-input', { 'date-picker-input--hasValue': !!value })}
          />
          {variant === 'mui' && (
            <label className="date-picker-mui-label" htmlFor={inputId}>
              {label}
            </label>
          )}
        </Fragment>
      )}
    </Rifm>
  );
});
