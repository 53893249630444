import { DataMutationFunction } from '../../types';
import { httpClient } from '../../httpClient';
import { convertToFormData } from '../../helpers';
import { isEmpty } from 'common/utils/isEmpty';

export const mutationSntValidate: DataMutationFunction = async (variables = {}): Promise<any> => {
  const { body = {} } = variables;

  const formData = convertToFormData(body);

  // if (false) {
  //   console.log('return errors');
  //   throw { data: fakeEsfErrors2 }; // eslint-disable-line
  // }

  const res = await httpClient.post('/snt/validate', formData);
  if (res?.data?.success === false) {
    throw res;
  } else if (!isEmpty(res?.data?.errors)) {
    throw res;
  } else {
    return res;
  }
};
