import { httpClient } from 'rest/httpClient';
import { DataQueryFunction } from 'rest/types';

export const queryDictSmallRetailOutletCheck: DataQueryFunction = async ({ tin }) => {
  const res = await httpClient.get(`/dict/smallRetailOutlet/check/${tin}`);
  if (res.data?.success === false || !res.data) {
    throw res;
  } else {
    return res;
  }
};
