import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import s from './DictionaryNodeT2.module.scss';
import { SvgIcon } from 'ui/common/elements/svg-icon';
import { Loader } from 'ui/common';

type Props = {
  className?: string;
  childNode?: boolean;
  data: any;
  getHeader: (data: any, loading: boolean) => React.ReactNode | string;
  getChildren?: (data: any) => Promise<Array<any>>;
  isFinal?: (data: any) => boolean;
};

export const DictionaryNodeT2: React.FC<Props> = ({ className, childNode, data, getHeader, getChildren, isFinal }) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [childrenData, setChildrenData] = useState<Array<any> | null>(null);
  const [loading, setLoading] = useState(false);

  const final = typeof isFinal === 'function' && isFinal(data);

  const onClick = () => {
    if (!final) {
      setExpanded((state) => !state);
    }
  };

  useEffect(() => {
    if (expanded && typeof getChildren === 'function') {
      setLoading(true);
      getChildren(data)
        .then((arr) => {
          setChildrenData(arr);
        })
        .finally(() => setLoading(false));
    }
  }, [expanded, data, getChildren]);

  return (
    <div
      className={clsx(className, s.wrapper, {
        [s.expanded]: expanded,
        [s.childNode]: childNode,
        [s.final]: final,
      })}
    >
      <div className={s.header} onClick={onClick}>
        {loading && <Loader className={s.nodeLoader} loaderClassName={s.loaderDots} overlay size="small" />}
        {!final && <SvgIcon name="chevron" className={s.chevron} />}
        {getHeader(data, loading)}
      </div>
      <div className={clsx(s.child, { [s.expanded]: expanded })}>
        {expanded &&
          !final &&
          Array.isArray(childrenData) &&
          childrenData.map((item) => (
            <DictionaryNodeT2
              key={item.code}
              childNode={true}
              data={item}
              getHeader={getHeader}
              getChildren={getChildren}
              isFinal={isFinal}
            />
          ))}
      </div>
    </div>
  );
};
