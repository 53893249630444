import { getLegasyUrl } from 'common/helpers';
import { SntMode } from '../constants';
import { generatePath } from 'react-router-dom';
import { PATH } from 'routing/paths';
import { History } from 'history';

/**
 * Перенавравоение на страницу бланка СНТ (в старом интерфейсе)
 */
export const navigateToSntPageLegacy = ({ mode, id }: { mode: SntMode; id?: string }) => {
  switch (mode) {
    case SntMode.new:
      window.location.assign(getLegasyUrl('/snt/new'));
      break;
    case SntMode.newPaper:
      window.location.assign(getLegasyUrl('/snt/fromPaper'));
      break;
    case SntMode.draft:
      window.location.assign(getLegasyUrl(`/snt/drafts/${id}`));
      break;
    case SntMode.fixed:
      window.location.assign(getLegasyUrl(`/snt/correction/${id}`));
      break;
    default:
      window.location.assign(getLegasyUrl(`/snt/${mode}/${id}`));
      break;
  }
};

/**
 * Перенавравоение на страницу бланка (новые страницы)
 */
export const navigateToSntPage = ({ mode, id, history }: { mode: SntMode; id?: string; history: History }) => {
  if (mode === SntMode.new || mode === SntMode.newPaper) {
    history.push(
      generatePath(PATH.SNT_FORM_NEW, {
        mode,
      })
    );
  } else if (id) {
    history.push(
      generatePath(PATH.SNT_FORM, {
        mode,
        id,
      })
    );
  }
};
