import { httpClient } from 'rest/httpClient';
import { DataQueryFunction } from 'rest/types';

export const queryVstoreFindCurrentSuccessorTnved: DataQueryFunction = async ({ productId, date }) => {
  const res = await httpClient.get(`/vstore/rest/gsvs/findCurrentSuccessorTnved`, { params: { productId, date } });

  if (res.data?.success === false || !res.data) {
    throw res;
  } else {
    return res;
  }
};
