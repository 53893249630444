import { httpClient } from 'rest/httpClient';
import { DataQueryFunction } from 'rest/types';

export const queryVstoreTaxpayerStoreId: DataQueryFunction = async ({ id }) => {
  const res = await httpClient.get(`/vstore/rest/taxpayerStore/id/${id}`);

  if (res.data?.success === false || !res.data) {
    throw res;
  } else {
    return res;
  }
};
