import { clearEmptyFields } from 'common/utils';
import { httpClient } from 'rest/httpClient';
import { DataQueryFunction } from 'rest/types';

export const queryVstoreGsvsFind: DataQueryFunction = async ({ filter }) => {
  const params = clearEmptyFields(filter);
  let url = `/vstore/rest/gsvs/children/fixedId/0`;
  if (params.date && Object.keys(params).length > 1) {
    url = `/vstore/rest/gsvs/find/criteria`;
  }

  const res = await httpClient.get(url, {
    params,
  });

  if (res.data?.success === false || !res.data) {
    throw res;
  } else {
    return res;
  }
};
