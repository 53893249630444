import React, { useMemo } from 'react';
import s from './ProductTable.module.scss';
import clsx from 'clsx';
import { ProductsTotalFields, ProductTableColumn } from './types';
import { numberWithSpaces } from 'common/utils';

type Props = {
  className?: string;
  columns: Array<ProductTableColumn>;
  /**
   * Словарь значений для ряда Итого: имя поля итого -> значение
   */
  totalsValues: any;
  /**
   * Таблица соответствия для ряда Итого: имя столбца -> параметры поля итого
   */
  totalsFields: ProductsTotalFields;
  label?: string;
};

export const ProductTableTotalsRow: React.FC<Props> = ({ className, columns, totalsValues, totalsFields, label }) => {
  const firstTotalColumnIndex = useMemo(() => {
    return columns.findIndex((col) => {
      if (totalsFields[col.name]) {
        return true;
      }
      return false;
    });
  }, [columns, totalsFields]);

  return (
    <tr className={clsx(className, s.row, s.totalRow)}>
      {label ? (
        <td className={s.totalLabelTd} colSpan={firstTotalColumnIndex > 0 ? firstTotalColumnIndex + 1 : 1}>
          {label}
        </td>
      ) : (
        <td></td>
      )}
      {columns
        .filter((col, index) => (label ? index >= firstTotalColumnIndex : true))
        .map((col) => {
          return (
            <td key={col.name} className={clsx(s.column)}>
              {numberWithSpaces(totalsValues?.[totalsFields[col.name || '']?.name] || '')}
            </td>
          );
        })}
    </tr>
  );
};
