import { DataMutationFunction } from '../../../types';
import { httpClient } from '../../../httpClient';
import { AxiosResponse } from 'axios';
import { convertToFormData } from 'rest/helpers';
import { clearEmptyFields } from 'common/utils';

export const mutationSntSave: DataMutationFunction<AxiosResponse> = async (variables): Promise<any> => {
  const { body } = variables || {};

  const formData = convertToFormData(clearEmptyFields(body || {}));

  const res = await httpClient.post('/snt/save', formData);

  if (res?.data?.success === false) {
    throw res;
  } else {
    return res;
  }
};
