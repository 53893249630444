import { ProfileType } from 'common/constants';
import { resolveTaxpayerFio, resolveTaxpayerNameOrFio } from 'common/helpers/taxpayer';
import React, { FC, useCallback } from 'react';
import { queryTaxpayerAutocompleteDebounced, queryTaxpayerRegAutocompleteBin } from 'rest';
import { useLocale } from 'services/l10n';
import { AutocompleteProps, FormAutocomplete, FormAutocompleteProps } from 'ui/common';

type Props = FormAutocompleteProps & {
  /**
   * Поиск с разделением выбора для Индивидуальный предприниматель (на ИП и ФЛ),
   * и добавлнием (Адвокат, Нотариус и тд.) для ФЛ.
   */
  extended?: boolean;
  /**
   * При использовании при регистрации, для неавторизованного пользователя
   */
  registration?: boolean;
  /**
   * Отображет название компании (если оно есть) вместо имени фл
   */
  companyName?: boolean;
  component?: FC<AutocompleteProps>;
};

export const FormAutocompleteIIN: React.FC<Props> = ({
  field,
  extended = false,
  registration = false,
  component,
  companyName,
  ...rest
}) => {
  const locale = useLocale();
  const { loc } = locale;
  const getOptionLabel = useCallback(
    (data) => {
      if (extended) {
        if (data.type === ProfileType.INDIVIDUAL) {
          return `${data.tin} ${resolveTaxpayerFio(data, locale)} (${loc('common.labels.fl')})`;
        }
        if ([ProfileType.LAWYER, ProfileType.BAILIFF, ProfileType.MEDIATOR, ProfileType.NOTARY].includes(data.type)) {
          return `${data.tin} ${resolveTaxpayerFio(data, locale)} ${loc(`common.profileType.${data.type}`)}`;
        }
        return `${data.tin} ${resolveTaxpayerNameOrFio(data, locale)}`;
      }
      if (companyName) {
        return `${data.tin} ${resolveTaxpayerNameOrFio(data, locale)}`;
      }
      return `${data.tin} ${resolveTaxpayerFio(data, locale)}`;
    },
    [locale, loc, extended, companyName]
  );

  const getSingleValue = useCallback((data) => {
    return data?.tin || '';
  }, []);

  return (
    <FormAutocomplete
      component={component}
      loadOptions={getLoadIINOptions(extended, registration)}
      getOptionLabel={getOptionLabel}
      getSingleValue={getSingleValue}
      field={
        field
          ? {
              ...field,
              value: typeof field.value === 'string' ? { tin: field.value } : field.value,
            }
          : undefined
      }
      {...rest}
      inputPattern={/[^0-9]/g}
    />
  );
};

export function getLoadIINOptions(extended: boolean, registration: boolean) {
  async function loadOptions(term: string) {
    if (term.length >= 12) {
      const res = await queryTaxpayerAutocompleteDebounced({ term });
      if (res.data) {
        return extended ? extendIINData(res.data) : [res.data];
      }
    }
    return [];
  }

  async function loadOptionsForRegistration(term: string) {
    if (term.length >= 12) {
      const res = await queryTaxpayerRegAutocompleteBin({ term });
      if (res.data) {
        return extended ? extendIINData(res.data) : [res.data];
      }
    }
    return [];
  }

  return registration ? loadOptionsForRegistration : loadOptions;
}

export function extendIINData(data: any): Array<any> {
  const result = [data];
  if (data.type === ProfileType.INDIVIDUAL_ENTREPRENEUR) {
    result.push({ ...data, type: ProfileType.INDIVIDUAL });
  }
  if (Array.isArray(data.registrationTypes)) {
    data.registrationTypes.forEach((type: ProfileType) => {
      if ([ProfileType.LAWYER, ProfileType.BAILIFF, ProfileType.MEDIATOR, ProfileType.NOTARY].includes(type)) {
        result.push({ ...data, type });
      }
    });
  }
  return result;
}
