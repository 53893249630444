import { httpClient } from '../../httpClient';
import { DataQueryFunction } from '../../types';

export const querySntToMptFind: DataQueryFunction = async (variables: any) => {
  const { number, storeId, change } = variables || {};

  const res = await httpClient.get(`sntToMpt/find`, { params: { number, storeId, change } });
  if (res.data?.success === false || !res.data) {
    throw res;
  } else {
    return res;
  }
};
