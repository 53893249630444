/**
 * convert number (1234567.89) to string '1 234 567.89'
 */
export function numberWithSpaces(number: number | string): string {
  if ((typeof number === 'number' && !isNaN(number)) || (typeof number === 'string' && number)) {
    const parts = String(number).split('.');
    return [parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' '), parts[1]].filter(Boolean).join('.');
    // return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }
  return '';
}
