import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FieldProps } from 'formik';
import { InputAutocomplete, InputAutocompleteProps } from '../controls';
import { FormValueProps } from './FormValueProps';
import { debounce } from 'common/utils';
import { preProccessValueDigits, preProccessValueFloat } from './FormInput';

export type FormInputAutocompleteProps = Partial<FieldProps> &
  Omit<InputAutocompleteProps, 'name' | 'value' | 'onChange' | 'onBlur' | 'onSelect'> & {
    getOptionValue?: (option: any) => any;
    onValueChange?: (props: FormValueProps<string>) => void;
    /**
     * Запуск события onChange только при смене фокуса
     */
    changeOnBlur?: boolean;
    debounceTimer?: number;
    /**
     * Если trrue - Позволяет вводить только цифры (0-9)
     * Если number - то числа с точкой, где number число знаков после точки
     */
    onlyDigits?: boolean | number;
    /**
     * Преобразование значения до добавления в форму
     */
    preProccessValue?: (value: string) => string;
  };

export const FormInputAutocomplete: React.FC<FormInputAutocompleteProps> = ({
  getOptionValue = (opt) => opt?.value,
  field,
  form,
  meta,
  onValueChange,
  changeOnBlur,
  debounceTimer,
  onlyDigits,
  preProccessValue,
  ...rest
}) => {
  const { name, onChange, onBlur, value } = field || {};
  const { setFieldValue } = form || {};

  const preProccessFunction = useMemo(() => {
    if (typeof preProccessValue === 'function') {
      return preProccessValue;
    }
    if (onlyDigits === true) {
      return preProccessValueDigits;
    }
    if (typeof onlyDigits === 'number') {
      return preProccessValueFloat.bind(undefined, onlyDigits);
    }
  }, [preProccessValue, onlyDigits]);

  // using inner state when changeOnBlur = true
  const [inputValue, setInputValue] = useState(value);

  //Костыль для обновления inputValue при changeOnBlur
  const valuesAreEqual = useRef(true);
  valuesAreEqual.current = value === inputValue;

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = event.target.value;
    if (preProccessFunction) {
      const preProcValue = preProccessFunction(newValue);
      if (preProcValue === null) {
        // Если preProccessFunction возвращает null не изменяем input value
        return;
      } else {
        newValue = preProcValue;
      }
    }
    if (changeOnBlur) {
      setInputValue(newValue);
    } else if (typeof debounceTimer === 'number') {
      setInputValue(newValue);
      debouncedOnChange(newValue, (value) => {
        if (onValueChange && form && name) {
          onValueChange({ value, name, form });
        } else {
          setFieldValue && name && setFieldValue(name, newValue);
        }
      });
    } else if (onValueChange && name && form) {
      onValueChange({ value: newValue, name, event, form });
    } else if (preProccessFunction) {
      setFieldValue && name && setFieldValue(name, newValue);
    } else {
      onChange && onChange(event);
    }
  };

  const onSelectOption = (option: any) => {
    console.log('onSelectOption');
    let newValue = getOptionValue(option);
    if (preProccessFunction) newValue = preProccessFunction(newValue);
    if (onValueChange && name && form) {
      onValueChange({ value: newValue, option, name, form });
    } else {
      setFieldValue && name && setFieldValue(name, newValue);
    }
  };

  const onInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    console.log('onInputBlur');
    if (changeOnBlur && inputValue !== value) {
      if (onValueChange && form) {
        !!name && onValueChange({ value: inputValue, name, form, event: e });
      } else {
        typeof setFieldValue === 'function' && !!name && setFieldValue(name, inputValue);
      }
    }
    typeof onBlur === 'function' && onBlur(e);
  };

  const debouncedOnChange = useMemo(
    () =>
      debounce((inputValue: any, onChange: (value: any) => void) => {
        onChange(inputValue);
      }, debounceTimer),
    [debounceTimer]
  );

  // console.log('render FormInput', { name, value, inputValue });

  useEffect(() => {
    // console.log('setInputValue effect');
    if (!valuesAreEqual.current && (changeOnBlur || typeof debounceTimer === 'number')) {
      setInputValue(value);
    }
  }, [value, changeOnBlur, debounceTimer]); // eslint-disable-line

  return (
    <InputAutocomplete
      {...rest}
      {...field}
      value={(changeOnBlur || typeof debounceTimer === 'number' ? inputValue : field?.value) ?? ''}
      onChange={onInputChange}
      onSelect={onSelectOption}
      onBlur={onInputBlur}
    />
  );
};
