import React, { useMemo } from 'react';
import s from './TableT2.module.scss';
import { getIn, setIn } from 'formik';
import { TableColumn } from 'ui/common/components/table/types/TableColumn';
import { getCellContent } from 'ui/common/components/table/helpers';

type Props = {
  className?: string;
  rows: Array<any>;
  columns: Array<TableColumn>;
  isSelectableTable?: boolean;
  totalLabel?: string;
};

export const TableT2TotalRow: React.FC<Props> = ({ className, rows, columns, isSelectableTable, totalLabel }) => {
  const totalData = useMemo(() => {
    if (!Array.isArray(rows)) {
      return {};
    }

    let firstTotalColumnIndex: number | undefined;
    const row = rows.reduce((acc, row) => {
      let total = acc;
      columns.forEach((col, colIndex) => {
        if (col.showTotal) {
          if (firstTotalColumnIndex === undefined) {
            firstTotalColumnIndex = colIndex;
          }
          total = setIn(total, col.name, (Number(getIn(total, col.name)) || 0) + (Number(getIn(row, col.name)) || 0));
        }
      });

      return total;
    }, {});
    return { firstTotalColumnIndex, row };
  }, [rows, columns]);

  return typeof totalData.firstTotalColumnIndex === 'number' && totalData.row ? (
    <tr>
      {isSelectableTable && <th></th>}
      {totalData.firstTotalColumnIndex ? (
        <th className={s.totalLabel} colSpan={totalData.firstTotalColumnIndex}>
          {totalLabel || 'Итого:'}
        </th>
      ) : null}
      {columns.map((col, index) =>
        index >= (totalData.firstTotalColumnIndex as number) ? (
          <th key={col.name}>{col.showTotal && getCellContent(totalData.row, 99999, col)}</th>
        ) : null
      )}
    </tr>
  ) : null;
};
