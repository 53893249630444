import React, { useCallback } from 'react';
import { queryDictExciseRateList } from 'rest/api/dictionary/queryDictExciseRateList';
import { LocaleStore, useLocale } from 'services/l10n';
import { FormSelect, FormSelectProps } from 'ui/common';

async function getExciseRateOptions({ loc }: LocaleStore) {
  const emptyOptions = [
    {
      value: '',
      label: loc('common.option.notSelected'),
    },
  ];
  const res = await queryDictExciseRateList();
  if (Array.isArray(res.data)) {
    return [...emptyOptions, ...res.data];
  }
  return emptyOptions;
}

type Props = FormSelectProps & {
  preProcessOptions?: (options: Array<any>) => Array<any>;
};

export const FormExciseRateSelect: React.FC<Props> = ({ preProcessOptions, ...rest }) => {
  const locale = useLocale();
  const { loc } = locale;

  const getOptionLabel = useCallback((data) => {
    return data.rate ?? data.label;
  }, []);

  const getOptionValue = useCallback((data) => {
    return data.rate ?? data.value;
  }, []);

  const noOptionsMessage = useCallback(() => loc('common.option.noOptions'), [loc]);

  const prepOptions = async () => {
    const options = await getExciseRateOptions(locale);
    if (typeof preProcessOptions === 'function') {
      return preProcessOptions(options);
    } else {
    }
    return options;
  };

  return (
    <FormSelect
      {...rest}
      fetchOptions={() => prepOptions()}
      isSearchable={true}
      noOptionsMessage={noOptionsMessage}
      getOptionValue={getOptionValue}
      getOptionLabel={getOptionLabel}
    />
  );
};
