export const SUPPORT_EMAIL = 'esfsd@kgd.minfin.gov.kz';
export const SUPPORT_PHONE = '+7 (7172) 72-51-61';

/**
 * Константы для EnterpriseType, BusinessProfileType
 */
export enum ProfileType {
  /**
   * "Отсутствует в справочнике"
   */
  NOT_SET = 'NOT_SET',

  /**
   * "Физическое лицо"
   */
  INDIVIDUAL = 'INDIVIDUAL',

  /**
   * "Индивидуальный предприниматель"
   */
  INDIVIDUAL_ENTREPRENEUR = 'INDIVIDUAL_ENTREPRENEUR',

  /**
   * "Частное предприятие"
   */
  PRIVATE_ENTERPRISE = 'PRIVATE_ENTERPRISE',

  /**
   * "Государственное предприятие/учреждение"
   */
  STATE_ENTERPRISE = 'STATE_ENTERPRISE',

  /**
   * "ЛЗЧП: Адвокат"
   */
  LAWYER = 'LAWYER',

  /**
   * "ЛЗЧП: Частный судебный исполнитель"
   */
  BAILIFF = 'BAILIFF',

  /**
   * "ЛЗЧП: Медиатор"
   */
  MEDIATOR = 'MEDIATOR',

  /**
   * "ЛЗЧП: Нотариус"
   */
  NOTARY = 'NOTARY',

  /**
   * "ЮР. ЛИЦО" (для фронта)
   */
  COMPANY = 'COMPANY',
}

/**
 * Коды стран, входящие в ЕАЭС
 */
export const EAEC_COUNRTY_CODES = ['RU', 'KZ', 'AM', 'BY', 'KG'];

export const isEaecCountry = (code: string) => EAEC_COUNRTY_CODES.includes(code);

export enum EDocumentStatus {
  CREATED = 'CREATED',
  WAIT_UTTN = 'WAIT_UTTN',
  PROCESSING = 'PROCESSING',
  PROCESSED = 'PROCESSED',
  ERROR = 'ERROR',
  DUPLICATE = 'DUPLICATE',
  WAITING_ADMIN_ACTIONS = 'WAITING_ADMIN_ACTIONS',
  CANCELLED = 'CANCELLED',
  CORRECTED = 'CORRECTED',
  SKIPPED = 'SKIPPED',
  CANCELED_BY_RELATED = 'CANCELED_BY_RELATED',
  DECLINED = 'DECLINED',
}

/**
 * Направление документа (входящий, исходящий, в работе)
 */
export enum DocDirection {
  /**
   * Исходящий
   */
  OUTBOUND = 'OUTBOUND',
  /**
   * Входящий
   */
  INBOUND = 'INBOUND',
  /**
   * В работе
   */
  IN_WORK = 'IN_WORK',
}

/**
 * Направление расчета в бланках ЭСФ СНТ
 */
export enum Calculation {
  DIRECT = 'DIRECT',
  REVERSE = 'REVERSE',
  AUTO = 'AUTO',
  MANUAL = 'MANUAL',
}
