import React, { useMemo } from 'react';
import { FormInputAutocomplete, FormInputAutocompleteProps } from 'ui/common';
import { getLoadIIKOptions } from './FormAutocompleteIIK';
import { useLocale } from 'services/l10n';

type Props = Omit<FormInputAutocompleteProps, 'loadOptions' | 'conditionForLoad'> & {
  iin?: string;
};

export const FormInputAutocompleteIIK: React.FC<Props> = ({ iin, ...rest }) => {
  const locale = useLocale();
  const { loc } = locale;
  const loadOptions = useMemo(() => getLoadIIKOptions(iin), [iin]);

  return (
    <FormInputAutocomplete
      loadOptions={loadOptions}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      loadingText={loc('common.loading')}
      {...rest}
    />
  );
};

const getOptionLabel = (data: any) => {
  return data.account;
};

const getOptionValue = (data: any) => {
  return data?.account || '';
};
