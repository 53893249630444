import React, { ChangeEvent, useState } from 'react';
import clsx from 'clsx';
import s from './Uploader.module.scss';
import { ReactComponent as PaperClipIcon } from './paper-clip.svg';
import { SvgIcon } from 'ui/common/elements';
import { ButtonIcon } from '../button-icon';
import { nanoid } from 'nanoid';
import { FileInput } from '../file-input';
import { UITheme } from 'ui/common/types';
import { formatFileSize } from 'common/utils';

/**
 * Данные файла - может использоваться вместо File (например при загрузке в base64);
 */
export type FileData = {
  data?: string;
  filename?: string;
  id?: number;
  url?: string;
  size?: number;
};

export type UploaderProps = {
  className?: string;
  fileInputClassName?: string;
  label?: string;
  files?: Array<File | FileData>;
  onSelect?: (files: Array<File>) => void;
  onRemove?: (index: number) => void;
  multiple?: boolean;
  fileAppearance?: 'line' | 'image';
  showFileSize?: boolean;
  fullWidth?: boolean;
  fullWidthLine?: boolean;
  theme?: UITheme;
  disabledInput?: boolean;
};

export const Uploader: React.FC<UploaderProps> = ({
  className,
  fileInputClassName,
  label,
  files,
  onSelect = (file) => console.log(file),
  onRemove = (file) => console.log(file),
  multiple,
  fileAppearance = 'line',
  fullWidthLine = true,
  showFileSize,
  theme = 'T1',
  fullWidth,
  disabledInput,
}) => {
  const [key, setKey] = useState<string>(nanoid());

  const inputChange = async (event: ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.files);
    if (!event.target.files) return;
    const files = Array.from(event.target.files);
    onSelect(files);
    setKey(nanoid());
  };

  return (
    <div className={clsx(className, s.wrapper, s[theme])}>
      <FileInput
        key={key}
        onChange={inputChange}
        multiple={multiple}
        label={label}
        theme={theme}
        fullWidth={fullWidth}
        className={fileInputClassName}
        disabled={disabledInput}
      />
      {Array.isArray(files) &&
        files.map((file, index) => {
          const fileName = file instanceof File ? file.name : file.filename;
          return fileAppearance === 'line' ? (
            <div key={index} className={clsx(s.fileLine, { [s.fullWidthLine]: fullWidthLine })} title={fileName}>
              <SvgIcon className={s.paperClipIcon} component={PaperClipIcon} />
              <span className={s.filename}>{fileName}</span>
              {showFileSize && file.size && <span className={s.filesize}>({formatFileSize(file.size)})</span>}
              <ButtonIcon
                iconName="close"
                className={s.closeButton}
                iconClassName={s.closeIcon}
                onClick={() => onRemove(index)}
              />
            </div>
          ) : fileAppearance === 'image' ? (
            <div key={index} className={s.fileImage} title={fileName}>
              <img
                src={file instanceof File ? URL.createObjectURL(file) : file.url}
                alt={fileName}
                className={s.image}
              />
              <div className={s.filename}>{fileName}</div>
              <ButtonIcon
                iconName="close"
                className={s.closeButton}
                iconClassName={s.closeIcon}
                onClick={() => onRemove(index)}
              />
            </div>
          ) : null;
        })}
    </div>
  );
};
