import React, { useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import s from './InputAutocomplete.module.scss';
import { InputProps } from './types';
import { Input } from './Input';
import { debounce } from 'common/utils';
import { useOutsideClick2 } from 'ui/common/hooks';

export type InputAutocompleteProps = InputProps & {
  loadOptions: (value: string) => Promise<any[]>;
  conditionForLoad?: (value: string) => boolean;
  getOptionLabel?: (option: any) => any;
  searchDebounceTimer?: number;
  onSelect?: (opt: any) => void;
  loadingText?: string;
};

export const InputAutocomplete: React.FC<InputAutocompleteProps> = ({
  className,
  loadOptions,
  getOptionLabel = (opt) => opt?.label,
  searchDebounceTimer,
  onChange,
  onSelect,
  conditionForLoad = () => true,
  readOnly,
  disabled,
  loadingText,
  ...rest
}) => {
  const [options, setOptions] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const optionsRef = useRef<any>();
  useOutsideClick2(optionsRef, () => setOptions([]), options?.length);

  const debouncedSearch = useMemo(
    () =>
      debounce((value: string) => {
        setLoading(true);
        loadOptions(value)
          .then((opts) => setOptions(opts))
          .finally(() => setLoading(false));
      }, searchDebounceTimer),
    [] // eslint-disable-line
  );

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (disabled || readOnly) {
      return;
    }
    const value = e.target.value;
    typeof onChange === 'function' && onChange(e);
    if (options.length) {
      setOptions([]);
    }
    if (typeof conditionForLoad === 'function' && conditionForLoad(value)) {
      debouncedSearch(value);
    }
  };

  const onInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    if (disabled || readOnly) {
      return;
    }
    console.log('focus');
    const value = e.target.value;
    if (typeof conditionForLoad === 'function' && conditionForLoad(value)) {
      debouncedSearch(value);
    }
  };

  const onOptionSelect = (opt: any) => {
    typeof onSelect === 'function' && onSelect(opt);
    setOptions([]);
  };

  return (
    <div className={clsx(className, s.wrapper)}>
      <Input {...rest} readOnly={readOnly} disabled={disabled} onChange={onInputChange} onFocus={onInputFocus} />
      {(Array.isArray(options) && options.length) || loading ? (
        <ul ref={optionsRef} className={s.options}>
          {loading ? (
            <li className={clsx(s.option, s.loading)}>{loadingText || 'Загрузка'}...</li>
          ) : (
            options.map((opt: any, index: number) => (
              <li
                key={index}
                className={s.option}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onOptionSelect(opt);
                }}
              >
                {getOptionLabel(opt)}
              </li>
            ))
          )}
        </ul>
      ) : null}
    </div>
  );
};
