import { httpClient } from '../../httpClient';
import { DataQueryFunction } from '../../types';

export const querySntViewId: DataQueryFunction = async (variables: any) => {
  const { id } = variables || {};

  const res = await httpClient.get(`snt/view/${id}`);
  if (res.data?.success === false || !res.data) {
    throw res;
  } else {
    return res;
  }
};
