import React, { useCallback } from 'react';
import { querySezList } from 'rest/api/dictionary';
import { LocaleStore, useLocale } from 'services/l10n';
import { FormSelect, FormSelectProps } from 'ui/common';

async function getSezOptions({ loc }: LocaleStore) {
  const emptyOptions = [
    {
      value: '',
      label: loc('common.option.notSelected'),
    },
  ];
  const res = await querySezList();
  if (Array.isArray(res.data)) {
    return [...emptyOptions, ...res.data];
  }
  return emptyOptions;
}

type Props = FormSelectProps & {
  withoutThirdLevel?: boolean;
  preProcessOptions?: (options: Array<any>) => Array<any>;
};

export const FormSEZSelect: React.FC<Props> = ({ preProcessOptions, ...rest }) => {
  const locale = useLocale();
  const { loc, choose } = locale;

  const getOptionLabel = useCallback(
    (data) => {
      return data.label ?? choose([data.nameRu, data.nameKz]);
    },
    [choose]
  );

  const getOptionValue = useCallback((data) => {
    return data.value ?? data.code;
  }, []);

  const noOptionsMessage = useCallback(() => loc('common.option.noOptions'), [loc]);

  const prepOptions = async () => {
    const options = await getSezOptions(locale);
    if (typeof preProcessOptions === 'function') {
      return preProcessOptions(options);
    } else {
    }
    return options;
  };

  return (
    <FormSelect
      {...rest}
      fetchOptions={() => prepOptions()}
      isSearchable={true}
      noOptionsMessage={noOptionsMessage}
      getOptionValue={getOptionValue}
      getOptionLabel={getOptionLabel}
    />
  );
};
