import React, { ChangeEvent, memo, useEffect, useMemo, useRef, useState, MouseEventHandler } from 'react';
import clsx from 'clsx';
import s from './TableT2.module.scss';
import { Checkbox } from 'ui/common/controls/checkbox';
import { TableRowT2 } from './TableRowT2';
import { TableColumn } from 'ui/common/components/table/types';
import { useIsFirstRenderRef } from 'ui/common/hooks/useIsFirstRenderRef';
import { SvgIcon } from 'ui/common';
import { ReactComponent as SortUpIcon } from 'assets/icons/sort-up.svg';

const emptyRows: Array<any> = [];
const emptyColumns: Array<TableColumn> = [];
const emptySelected = {};

type Props = {
  className?: string;
  classNameInner?: string;
  columns?: Array<TableColumn>;
  rows?: Array<any>;
  selectable?: boolean;
  /**
   * Режим выбора строк: Множествееный (multi), только одну строку (single)
   */
  selectableMode?: 'multi' | 'single';
  getRowId?: (row: any, index: number) => string | number;
  getRowStyle?: (row: any) => { [key: string]: string } | undefined;
  onChangeSelected?: (selectedRows: Array<any>) => void;
  renderFooter?: (filteredColumns: Array<any>) => React.ReactNode;
  variant?: 'default' | 'rounded';
  scrollable?: boolean;
  loading?: boolean;
  loaderRender?: () => React.ReactNode;
  oneLine?: boolean;
  resizableColumns?: boolean;
  onColumnWidthChange?: (widths: { [name: string]: number | undefined }) => void;
  /**
   * Кастомный обработчик при выборе ряда в таблице
   */
  onSelectRow?: (props: OnSelectRowProps) => void;
  sortColumnName?: string;
  sortOrder?: 'asc' | 'desc';
  onColumnHeadClick?: (column: TableColumn) => void;
};

export const TableT2: React.FC<Props> = memo(
  ({
    className,
    classNameInner,
    columns = emptyColumns,
    rows = emptyRows,
    selectable = false,
    selectableMode = 'multi',
    getRowId = (row: any) => row.id,
    getRowStyle = (row: any) => undefined,
    onChangeSelected,
    scrollable,
    loading,
    loaderRender,
    oneLine,
    renderFooter,
    resizableColumns,
    onColumnWidthChange,
    variant = 'default',
    onSelectRow,
    sortColumnName,
    sortOrder = 'asc',
    onColumnHeadClick,
  }) => {
    const [selected, setSelected] = useState<{ [key: string]: boolean }>(emptySelected);

    const [selectedAll, setSelectedAll] = useState<boolean>(false);

    const [colWidths, setColWidths] = useState<{
      [name: string]: number | undefined;
    }>(Object.fromEntries(columns.map((col) => [col.name, col.width || 200])));

    const isMounted = useIsFirstRenderRef();

    const filteredColumns = useMemo(() => columns.filter((col) => !col.hidden), [columns]);

    const resizeData = useRef<{
      name: string;
      column: any;
      pageX: number;
      columnWidth: number;
    }>();

    const onResizeMouseDown: MouseEventHandler<HTMLSpanElement> = (e) => {
      const column = e.currentTarget?.parentElement;
      if (column) {
        resizeData.current = {
          name: column.getAttribute('data-name') || '',
          column,
          columnWidth: column.offsetWidth,
          pageX: e.pageX,
        };
      }
    };

    const onSelectRowDefault = (row: any, index: number, selected: boolean, selectOne: boolean = false) => {
      if (selectOne || selectableMode === 'single') {
        setSelected(() => ({
          [getRowId(row, index)]: selected,
        }));
      } else {
        setSelected((state) => ({
          ...state,
          [getRowId(row, index)]: selected,
        }));
      }
    };

    const onSelectAll = (e: ChangeEvent<HTMLInputElement>) => {
      const checked = e.target.checked;
      setSelected((state) =>
        checked ? Object.fromEntries(rows.map((row, index) => [[getRowId(row, index)], true])) : emptySelected
      );
    };

    useEffect(() => {
      setSelected(emptySelected);
    }, [rows]); // eslint-disable-line

    useEffect(() => {
      if (loading) {
        setSelected(emptySelected);
      }
    }, [loading]); // eslint-disable-line

    useEffect(() => {
      if (typeof onChangeSelected === 'function')
        onChangeSelected(rows.filter((row, index) => selected[getRowId(row, index)]));
    }, [selected]); // eslint-disable-line

    useEffect(() => {
      if (selectableMode === 'multi') {
        const newSelectedAll = rows.length === Object.values(selected).filter(Boolean).length;
        if (newSelectedAll !== selectedAll) {
          setSelectedAll(newSelectedAll);
        }
      }
    }, [selected, selectableMode, rows, selectedAll]);

    useEffect(() => {
      if (resizableColumns) {
        const onResizeMouseMove = (e: globalThis.MouseEvent) => {
          if (resizeData.current?.column) {
            const { column, columnWidth, pageX } = resizeData.current;
            var diffX = e.pageX - pageX;
            column.style.width = columnWidth + diffX + 'px';
          }
        };
        const onResizeMouseUp = (e: globalThis.MouseEvent) => {
          if (resizeData.current?.column) {
            const { name, column } = resizeData.current;
            console.log({ name, width: column.offsetWidth });
            setColWidths((state) => ({ ...state, [name]: column.offsetWidth }));
            resizeData.current = undefined;
          }
        };
        document.addEventListener('mousemove', onResizeMouseMove);
        document.addEventListener('mouseup', onResizeMouseUp);
        return () => {
          document.removeEventListener('mousemove', onResizeMouseMove);
          document.removeEventListener('mouseup', onResizeMouseUp);
        };
      }
    }, [resizableColumns]);

    useEffect(() => {
      if (!isMounted.current && typeof onColumnWidthChange === 'function') onColumnWidthChange(colWidths);
    }, [colWidths]); // eslint-disable-line

    return (
      <div
        className={clsx(className, s.wrapper, s[variant], {
          [s.selectable]: selectable,
          [s.oneLine]: oneLine,
        })}
      >
        {loading && typeof loaderRender === 'function' && <div className={s.loaderContainer}>{loaderRender()}</div>}

        <div className={clsx(s.inner, classNameInner, { [s.scrollable]: scrollable })}>
          <table>
            <thead>
              <tr>
                {selectable && (
                  <th className={s.checkCell}>
                    {selectableMode === 'multi' && (
                      <Checkbox
                        checked={selectedAll}
                        onChange={(e) => onSelectAll(e)}
                        className={s.checkBox}
                        theme="T2"
                      />
                    )}
                  </th>
                )}
                {filteredColumns.map((col) => (
                  <th
                    key={col.name}
                    style={{
                      ...(colWidths[col.name] ? { width: colWidths[col.name] } : {}),
                    }}
                    className={clsx({ [s.sortableTh]: col.sortable, [s.sortedTh]: sortColumnName === col.name })}
                    data-name={col.name}
                    title={col.labelTitle || col.label}
                    onClick={() => col.sortable && typeof onColumnHeadClick === 'function' && onColumnHeadClick(col)}
                  >
                    {col.label}
                    {resizableColumns && (
                      <span
                        className={s.resizeHandler}
                        onMouseDown={onResizeMouseDown}
                        onClick={(e) => e.stopPropagation()}
                      />
                    )}
                    {sortColumnName === col.name && (
                      <SvgIcon
                        className={clsx(s.sortIcon, {
                          [s.sortAsc]: sortOrder === 'asc',
                          [s.sortDesc]: sortOrder === 'desc',
                        })}
                        component={SortUpIcon}
                      />
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className={clsx({ [s.loading]: loading })}>
              {rows.map((row: any, index: number) => (
                <TableRowT2
                  key={getRowId(row, index)}
                  row={row}
                  rowIndex={index}
                  columns={filteredColumns}
                  getRowId={getRowId}
                  getRowStyle={getRowStyle}
                  onSelect={
                    typeof onSelectRow === 'function'
                      ? (row, index, selected, selectOne) =>
                          onSelectRow({ row, index, selected, selectOne, setSelected })
                      : onSelectRowDefault
                  }
                  selectable={selectable}
                  selected={selected[getRowId(row, index)]}
                />
              ))}
            </tbody>
            {typeof renderFooter === 'function' && <tfoot>{renderFooter(filteredColumns)}</tfoot>}
          </table>
        </div>
      </div>
    );
  }
);

export type OnSelectRowProps = {
  row: any;
  index: number;
  selected: boolean;
  selectOne: boolean | undefined;
  setSelected: React.Dispatch<
    React.SetStateAction<{
      [key: string]: boolean;
    }>
  >;
};
