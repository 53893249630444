import { DocDirection } from 'common/constants';
import { UserStore } from 'common/stores/user-store';
import { SntFormValues } from '../types';

type SntRow = any;

/**
 * Определяет направление документа СНТ (Входящий, Исходящий)
 * snt: Данные СНТ (ряд в журнале СНТ или данные формы СНТ)
 */
export const solveSntDirection = (
  snt: SntRow | SntFormValues,
  user: UserStore
): DocDirection.INBOUND | DocDirection.OUTBOUND => {
  const creatorTin = snt?.creatorTin || snt?._info?.creatorTin;
  const creatorType = snt?.creatorType || snt?._info?.creatorType;
  if (creatorTin === user.currentUser?.currentUserTin && creatorType !== 'RECIPIENT') {
    return DocDirection.OUTBOUND;
  }
  return DocDirection.INBOUND;
};

/**
 * Определяет является ли пользователь получателем СНТ
 * snt: Данные СНТ (ряд в журнале СНТ или данные формы СНТ)
 */
export const solveSntIsRecipient = (snt: SntRow | SntFormValues, user: UserStore): boolean => {
  const recipientTin = snt?.recipientTin || snt?._info?.recipientTin;
  const recipientBranchTin = snt?.recipientBranchTin || snt?._info?.recipientBranchTin;
  return [recipientTin, recipientBranchTin].includes(user.currentUser?.currentUserTin);
};
