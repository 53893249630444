import { clearEmptyFields } from 'common/utils';
import { httpClient } from 'rest/httpClient';
import { DataQueryFunction } from 'rest/types';

export const queryVstoreGsvsChildrenFixedId: DataQueryFunction = async ({ filter, id }) => {
  const res = await httpClient.get(`/vstore/rest/gsvs/children/fixedId/${id}`, {
    params: { ...clearEmptyFields(filter) },
  });

  if (res.data?.success === false || !res.data) {
    throw res;
  } else {
    return res;
  }
};
