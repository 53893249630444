import { LocaleStore } from 'services/l10n';

export const getStoreTypeCodeOptions = ({ loc }: LocaleStore): Array<{ value: any; label: string }> => [
  {
    value: '',
    label: loc('common.option.notSelected'),
  },
  {
    value: 'POINT_OF_SALE',
    label: loc('c.store.storeTypeCode.POINT_OF_SALE'),
  },
  {
    value: 'STORE_HOUSE',
    label: loc('c.store.storeTypeCode.STORE_HOUSE'),
  },
  {
    value: 'MOBILE_STORE',
    label: loc('c.store.storeTypeCode.MOBILE_STORE'),
  },
];
