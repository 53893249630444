import React, { ChangeEvent, FC, memo, useEffect, useRef, useState } from 'react';
import s from './ProductTable.module.scss';
import { ProductTableColumn, ProductFormProps } from './types';
import { Formik, FormikProps, getIn } from 'formik';
import clsx from 'clsx';
import { Checkbox } from 'ui/common/controls/checkbox/Checkbox';
import { ErrorSign } from 'ui/common/elements/error-sign/ErrorSign';
import { errorToString } from 'ui/common/form-controls/FormField';
import { useOutsideClick2 } from 'ui/common/hooks';
import { numberWithSpaces } from 'common/utils';

type Props = {
  className?: string;
  rowIndex: number;
  row: any;
  errors?: any;
  columns: Array<ProductTableColumn>;
  onChange: (values: any, index: number) => void;
  productFormComponent?: FC<ProductFormProps>;
  productFormComponentProps?: { [key: string]: any };
  selected?: boolean;
  onSelect: (row: any, index: number, selected: boolean) => void;
  editable?: boolean;
};

export const ProductTableRow: React.FC<Props> = memo(
  ({
    className,
    rowIndex,
    row,
    errors,
    columns,
    onChange,
    productFormComponent: ProductFormComponent,
    productFormComponentProps = {},
    selected = false,
    onSelect,
    editable,
  }) => {
    const [edit, setEdit] = useState(false);

    const rowFormRef = useRef<null | FormikProps<any>>(null);
    const trRef = useRef<any>(null);
    const mouseCoord = useRef<{ x: number; y: number }>({ x: 0, y: 0 });

    const submitForm = () => {
      if (rowFormRef.current) {
        const { values, dirty } = rowFormRef.current;
        console.log('submitRowForm', values);
        if (dirty) {
          onChange(values, rowIndex);
        }
        setEdit(false);
      }
    };

    useOutsideClick2(trRef, submitForm, edit);

    const onClick: React.MouseEventHandler<HTMLTableRowElement> = (e) => {
      e.stopPropagation();
      if (!edit && editable) {
        setEdit(true);
        mouseCoord.current.x = e.clientX;
        mouseCoord.current.y = e.clientY;
      }
    };

    const onKeyDown: React.KeyboardEventHandler<HTMLTableRowElement> = (e) => {
      // console.log(e.key);
      if (edit) {
        switch (e.key) {
          case 'Enter':
            submitForm();
            break;
          case 'Escape':
            resetForm();
            break;
          default:
            break;
        }
      }
    };

    const resetForm = () => {
      if (rowFormRef.current) {
        rowFormRef.current.resetForm();
        setEdit(false);
      }
    };

    useEffect(() => {
      if (edit) {
        const el = document.elementFromPoint(mouseCoord.current.x, mouseCoord.current.y);
        // console.log(el, el?.tagName);
        if (el && el.tagName === 'INPUT' && !(el as HTMLInputElement).readOnly) {
          // console.log('focusing');
          (el as HTMLInputElement).focus();
        }
      }
    }, [edit]);

    const selectHandle = (e: ChangeEvent<HTMLInputElement>) => {
      onSelect(row, rowIndex, e.target.checked);
    };

    return (
      <tr onClick={onClick} onKeyDown={onKeyDown} ref={trRef} className={clsx({ [s.selectedRow]: selected })}>
        <td className={s.checkCell}>
          <Checkbox className={s.checkBox} checked={selected} onChange={selectHandle} theme="T2" disabled={!editable} />
        </td>
        {edit && ProductFormComponent ? (
          <Formik
            initialValues={{ ...row }}
            initialErrors={errors}
            validateOnChange={false}
            validateOnBlur={false}
            validateOnMount={false}
            enableReinitialize={false}
            onSubmit={() => {}}
          >
            {(form) => {
              rowFormRef.current = form;
              return (
                <ProductFormComponent
                  rowIndex={rowIndex}
                  form={form}
                  columns={columns}
                  classes={{
                    formTdClass: s.formTd,
                    controlClass: s.formControl,
                  }}
                  {...productFormComponentProps}
                />
              );
            }}
          </Formik>
        ) : (
          columns.map((col, index) => {
            let content = col.getContent
              ? col.getContent(row, rowIndex, getIn(row, col.name))
              : col.getFormValue
              ? col.getFormValue(row, rowIndex, getIn(row, col.name))
              : getIn(row, col.name);
            const error = errorToString(getIn(errors, col.name));

            if (col.onlyDigits) {
              content = numberWithSpaces(content);
            }

            return (
              <td
                key={col.name}
                style={col.style ? { ...col.style, width: undefined } : undefined}
                title={
                  col.getTitle
                    ? col.getTitle(row)
                    : typeof content === 'string' || typeof content === 'number'
                    ? String(content)
                    : undefined
                }
                className={clsx({ [s.errorCell]: error })}
              >
                <span className={s.cellContent}>{content}</span>
                {!!error && (
                  <ErrorSign
                    className={s.errorIcon}
                    errorMessage={error}
                    small
                    style={{ zIndex: columns.length - index }}
                  />
                )}
              </td>
            );
          })
        )}
      </tr>
    );
  },
  (prev, next) => {
    const isEqual =
      prev.row === next.row &&
      prev.selected === next.selected &&
      JSON.stringify(prev.errors) === JSON.stringify(next.errors);
    console.log(`row ${prev.rowIndex}: ${isEqual}`);
    return isEqual;
  }
);
