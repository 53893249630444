import { getIn, setIn } from 'formik';

/**
 * Поиск данных с обшибками (поле message, или массив errors с ошибками)
 */
export function findErrorData(res: any) {
  return (
    res?.response?.data?.message ||
    res?.response?.data?.errors ||
    res?.data?.message ||
    res?.data?.errors ||
    res?.message ||
    res?.errors ||
    res
  );
}

/**
 * Поиск общего сообщения об ошибке
 */
export function solveErrorMessage(res: any): string {
  const errorData = findErrorData(res);
  if (typeof errorData === 'string') {
    return errorData;
  }
  if (Array.isArray(errorData)) {
    // Ошибки без property считаем общими ошибками
    const error = errorData.filter((err) => !err.property)[0];
    return error?.message || error?.text || '';
  }
  return '';
}

/**
 * Поиск ошибок полей формы
 */
export function solveFormErrors(res: any): { [name: string]: any } {
  const errorData = findErrorData(res);
  return transformFormErrors(errorData);
}

/**
 * Преобразует из массива ошибок Array<{ property: 'customer.name', text: 'error text' }>
 * в объект ошибок для формы { customer: { name: 'error text' } }
 */
export function transformFormErrors(errorData: any): { [name: string]: any } {
  if (Array.isArray(errorData)) {
    const result = errorData
      .filter((err) => err.text || err.message) // Ошибки с полем text или message считаем ошибками формы
      .reduce((acc, err, index) => {
        const errorText = err.text || err.message;
        const property = err.property || errorText; // если нет поля property, то считаем ключом текст ошибки (ошибка не будет привязана к полю формы, но она останется в данных ошибок)
        let existingError = getIn(acc, property);
        if (
          !existingError ||
          typeof existingError === 'string' ||
          (Array.isArray(existingError) && existingError.every((text) => typeof text === 'string'))
        ) {
          return setIn(
            acc,
            property,
            existingError
              ? Array.isArray(existingError)
                ? [...existingError, errorText]
                : [existingError, errorText]
              : errorText
          );
        }
        return acc;
      }, {});
    return result;
  }
  return {};
}
