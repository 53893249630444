import React from 'react';
import clsx from 'clsx';
import s from './FieldLabel.module.scss';
import { FieldDirection } from './FieldDirection';
import { UITheme } from '../types';

export type UIFieldLabelProps = {
  className?: string;
  label?: string;
  htmlFor?: string;
  direction?: FieldDirection;
  readOnly?: boolean;
  theme?: UITheme;
  required?: boolean;
};

export const FieldLabel: React.FC<UIFieldLabelProps> = ({
  className,
  label,
  htmlFor,
  direction,
  readOnly,
  children,
  theme = 'T1',
  required,
}) => {
  const stopPropagation = (e: React.SyntheticEvent) => {
    e.stopPropagation();
  };

  return (
    <label
      className={clsx(className, s.wrapper, s[theme], {
        [s.inline]: direction === 'line',
        [s.reverse]: direction === 'line-reverse',
        [s.readOnly]: readOnly,
        [s.required]: required,
      })}
      htmlFor={htmlFor}
      onClick={stopPropagation}
    >
      {label || children}
    </label>
  );
};
