/**
 * Remove trailng zeroes from decimal part of a number
 */
export function removeDecZero(value: string) {
  if (value.indexOf('.') !== -1) {
    const decVal = value.split('.')[1];
    let i = 0;
    while (i < decVal.length) {
      if (decVal.charAt(decVal.length - i - 1) !== '0') break;
      i++;
    }
    if (i >= 0 && i === decVal.length) {
      i++;
    }
    if (i > 0) {
      value = value.slice(0, -i);
    }
  }
  return value;
}
