import React from 'react';
import clsx from 'clsx';
import s from './RadioBlock.module.scss';

export type RadioBlockProps = {
  className?: string;
  value?: string | boolean;
  onChange?: (value: string | boolean) => void;
  justify?: 'start' | 'evenly';
  disabled?: boolean;
  readOnly?: boolean;
};

export const RadioBlock: React.FC<RadioBlockProps> = ({
  className,
  children,
  value,
  onChange,
  justify = 'start',
  disabled,
  readOnly,
}) => {
  return (
    <div
      className={clsx(className, s.wrapper, {
        [s.evenly]: justify === 'evenly',
      })}
    >
      {React.Children.map(children, function (child) {
        if (React.isValidElement(child)) {
          return React.cloneElement<any>(child, {
            className: clsx(child.props.className, s.button),
            selectedClassName: s.selected,
            value,
            onChange,
            disabled: disabled || readOnly,
          });
        }
      })}
    </div>
  );
};
