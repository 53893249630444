import { clearFalsyProps } from 'common/utils';
import { httpClient } from '../../httpClient';
import { DataQueryFunction } from '../../types';
import { toPaginationParams } from 'rest/helpers';

export const queryVstoreTaxpayerStoreSntDetailedList: DataQueryFunction = ({ filter, pagination }) => {
  return httpClient.get(`/vstore/rest/taxpayerStore/snt/detailedList`, {
    params: {
      ...clearFalsyProps(filter),
      ...toPaginationParams(pagination),
    },
  });
};
